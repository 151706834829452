import { Button, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BottomButton } from "../molecules/BottomButton";
import CustomDialog from "../molecules/CustomDialog";
import { Title } from "../molecules/Title";
import { UserName } from "../molecules/UserName";
import OcrCard from "../organisms/CardForOcr";
import axios from "axios";
import { NumberInfo, defaultNumber } from "../types/NumberInfo";

function Test({menu, userName, setMenu}:{menu:number, userName:string, setMenu:React.Dispatch<React.SetStateAction<number>>}) {
  const [openSuccess, setOpenSuccess ] = useState(false);
  const [openFailure, setOpenFailure ] = useState(false);
  // const [num1, setNum1 ] = useState("");
  // const [num1_ai, setNum1_ai ] = useState("");
  const [ num1, setNum1 ] = useState<NumberInfo>(defaultNumber);
  // const [num2, setNum2 ] = useState("");
  // const [num2_ai, setNum2_ai ] = useState("");
  const [ num2, setNum2 ] = useState<NumberInfo>(defaultNumber);
  const [ reset, setReset ] = useState(false);
  const [ errorText, setErrorText ] = useState("");
  const [ testData, setTestData] = useState("");
  const [ detail, setDetail ] = useState("");
  const [ authError, setAuthError ] = useState(false);
  const [ apiError, setApiError ] = useState(false);

  // const target = "28N+k2Ve";
  const error1 = "正：照合文字列と一致しません";
  const error2 = "誤：照合文字列と同じです";

  const loadTestData = async() => {
    const apiUrl = process.env.REACT_APP_ROOT_URL + 'load-testdata/'
    const header = {'content-type':'application/json'}

    axios
      .get(apiUrl, {headers:header})
      .then((response) => {
        setTestData(response.data.test_data);
      })
      .catch(() => {
        setTestData("テスト用データを取得できません");
      })
  }

  useEffect(()=>{loadTestData()},[]);

  const runTest = async() => {
    const apiUrl = process.env.REACT_APP_ROOT_URL + 'test/';
    const employee_id = localStorage.getItem("employeeId");
    const requestBody = {'test_data':testData, 'obtained_string_ok':num1.num,
    'obtained_string_ng':num2.num, 'employee_id':employee_id}
    const header = {'content-type':'application/json','Authorization':'Bearer '+ localStorage.getItem("authToken")}

    axios
      .post(apiUrl, requestBody, {headers:header})
      .then((response) => {
        if (response.data.result == 1){
          localStorage.setItem("testedAt",response.data.tested_at);
          setOpenSuccess(true);
        } else {
          setErrorText(response.data.detail);
          setOpenFailure(true);
        }
      })
      .catch(function(error){
        if(error.response.status){
          try {
            setDetail(error.response.data.detail);
          } catch {}
          if(error.response.status == 403){
            setAuthError(true);
          } else {
            setApiError(true);
          }
        } else {
          try {
            setDetail(error.message);
          } catch {}
          setApiError(true);
        }
      })
  }

  const apiCallTemplate = async() => {
    const apiUrl = process.env.REACT_APP_ROOT_URL + '';
    const requestBody = {}
    const header = {'content-type':'application/json','Authorization':'Bearer '+ localStorage.getItem("authToken")}

    axios
      .post(apiUrl, requestBody, {headers:header})
      .then((response) => {
        console.log(response.data);
      })
      .catch(function(error){
        if(error.response.status){
          try {
            setDetail(error.response.data.detail);
          } catch {}
          if(error.response.status == 403){
            setAuthError(true);
          } else {
            setApiError(true);
          }
        } else {
          try {
            setDetail(error.message);
          } catch {}
          setApiError(true);
        }
      })
  }

  return (
    <>
      <Title>点検</Title>
      <UserName>{userName}</UserName>
      <Container component = "main" maxWidth = "xs">
          <Grid container
            rowSpacing = {1}
            display = "flex"
            direction = "column"
            >
            <Grid item>
            <Typography
                    component = "h2"
                    variant = "h6"
                    textAlign = "left"
                    sx = {{m:1, fontWeight : 'bold'}}
                    >
                    照合文字列：{testData}
                </Typography>
            </Grid>
            <Grid item>
              <OcrCard numberInfo = {num1} flgReadOnly= {true} labelText = "読取文字列" setNumberInfo = {setNum1} reset = {reset} menu={menu} setMenu={setMenu} expectedDoc = {1}>正</OcrCard>
            </Grid>
            <Grid item>
              <OcrCard numberInfo = {num2} flgReadOnly= {true} labelText = "読取文字列" setNumberInfo = {setNum2} reset = {reset} menu={menu} setMenu={setMenu} expectedDoc = {1}>誤</OcrCard>
            </Grid>
            <Grid item>
              <Button
                // startIcon = {<LoginIcon />}
                variant = "contained"
                fullWidth
                size = "large"
                // sx = {{mt:1, mb:1}}
                onClick = {() => runTest()}
                disabled = {!(num1.num !=="" && num2.num !== "")}
                >
                  点検
              </Button>
            </Grid>
          </Grid>
        </Container>
        <CustomDialog
          title = "点検OK"
          buttonText = "確認"
          // flagOnClose
          onClick = {()=>{
            setReset(!reset);
            setOpenSuccess(false);
          }}
          open = {openSuccess}
          bgcolor = '#4caf50' //success light
        />
        <CustomDialog
          title = "点検NG"
          text = {errorText}
          buttonText = "解除"
          // flagOnClose
          onClick = {()=>{
            setOpenFailure(false);
          }}
          open = {openFailure}
          bgcolor = '#ef5350' //error light
        />
        <CustomDialog
            title = "認証エラー"
            text = {detail+"：ログインし直してください"}
            buttonText = "確認"
            onClick = {()=>{
              localStorage.removeItem("authToken");
              localStorage.removeItem("userName");
              localStorage.removeItem("employeeId");
              setMenu(0);
            }}
            open = {authError}
          />
        <CustomDialog
            title = "通信エラー"
            text = {detail}
            buttonText = "確認"
            onClick = {()=>{
              setApiError(false);
            }}
            open = {apiError}
          />
      <BottomButton onClick = {() => setMenu(0)}>TOP MENU</BottomButton>
    </>
    );
  }
  export default Test;