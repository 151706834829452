import { Button, Card, CardMedia, Grid, TextField, Typography } from "@mui/material";
import { ReactNode, useState } from "react";
import { MenuButton } from "./MenuButton";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { NumberInfo } from "../types/NumberInfo";

type NewCameraCardProps ={
    buttonText?: string,
    onButtonClick?: React.MouseEventHandler<HTMLButtonElement>,
    textLabel?: string,
    // defaultVal?: string,
    imageURL?: any,
    // onTextChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
    // number: string,
    // setNumber:React.Dispatch<React.SetStateAction<string>>,
    numberInfo: NumberInfo,
    setNumberInfo: React.Dispatch<React.SetStateAction<NumberInfo>>,
    children: ReactNode,
    // textRef?: React.MutableRefObject<string>,
    flgReadOnly?:boolean,
    loading:boolean,
}

export const NewCameraCard = (props:NewCameraCardProps) => {
    const { buttonText ="", onButtonClick = ()=> {}, textLabel="", imageURL, numberInfo, setNumberInfo, children, flgReadOnly = false, loading } = props

    const [ imgOpen, setImgOpen ] = useState(false);

    return(
        <Card sx = {{width:"100%"}}>
            <Grid container
                display="flex"
                //   justifyContent= "center"
                alignItems="center"
                  // direction = "column"
                >
            <Grid item xs={5}>
                <Typography
                    component = "h2"
                    variant = "h6"
                    textAlign = "left"
                    sx = {{m:1, fontWeight : 'bold'}}
                    >
                    {children}
                </Typography>
            </Grid>
            <Grid item xs={7} sx = {{paddingInline:1}}>
                <LoadingButton
                            loading= {loading}
                            startIcon = {<CameraAltIcon />}
                            onClick = {onButtonClick}
                            fullWidth
                            variant = "contained"
                            size = "large"
                            sx= {{my:1}}
                            >{buttonText}</LoadingButton>
                {/* <MenuButton onClick = {onButtonClick} icon = {<CameraAltIcon />}>{buttonText}</MenuButton> */}
            </Grid>
            {imageURL !== "" && imageURL !== undefined &&
            <React.Fragment>
                <Grid item xs = {12}>
                    <Button
                        onClick = {()=>setImgOpen(!imgOpen)}
                        startIcon = {imgOpen? <RemoveCircleIcon/>:<AddCircleIcon />}>
                        画像を{imgOpen?"閉じる":"確認する"}
                    </Button>
                </Grid>
                {imgOpen &&
                    <Grid item>
                    <CardMedia component = "img" src = {imageURL} />
                    </Grid>
                }
            </React.Fragment>
            }
            <TextField
                id = {textLabel}
                placeholder = {textLabel}
                variant = "outlined"
                margin = "normal"
                fullWidth
                sx = {{ paddingInline:1}}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>{
                    setNumberInfo({...numberInfo, 'num':event.target.value});
                }}
                value = {numberInfo.num}
                InputProps={{
                    readOnly: flgReadOnly,
                }}
            />
            </Grid>
        </Card>
    )
};