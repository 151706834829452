import { useEffect, useRef, useState } from "react";
import { useHooks } from "../hooks/imageHooks";
import { styles } from "../styles";
import { Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, TextField, Typography } from "@mui/material";
import { MenuButton } from "../molecules/MenuButton";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CloseIcon from '@mui/icons-material/Close';
import { useInspectionHooks } from "../hooks/inspectionHooks";
import DeleteIcon from '@mui/icons-material/Delete';
import { NumberObj } from "../types/NumberObj";
import axios from 'axios';
import CustomDialog from "../molecules/CustomDialog";
import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";

const CardForInspection = (props: {reset : boolean, setReqNumbers : React.Dispatch<React.SetStateAction<NumberObj[]>>, setMenu:React.Dispatch<React.SetStateAction<number>>}) => {
    const {reset, setReqNumbers, setMenu} = props;
    const { handleFiles, imageContainerRef, inputFileRef, openDialog, resetSelection, objectURL, selectedFile } = useHooks();
    const didMountRef = useRef(false);
    const { numbers, setNumbers, count, handleEdit, addNumber, handleDelete } = useInspectionHooks();
    const [ openReset, setOpenReset ] = useState(false);

    const [ detail, setDetail ] = useState("");
    const [ authError, setAuthError ] = useState(false);
    const [ apiError, setApiError ] = useState(false);

    const [ isLoading, setIsLoading ] = useState(false);
    const [ apiTime, setApiTime ] = useState(0);
    const [ wholeTime, setWholeTime ] = useState(0);

    const runOcr = async() => {
        const apiUrl = process.env.REACT_APP_ROOT_URL + 'daido-ocr/';
        const header = {'content-type':'application/json','Authorization':'Bearer '+ localStorage.getItem("authToken")}

        setIsLoading(true);
        const startTime = new Date().getTime();
        const formData = new FormData();
        if (selectedFile !== null) {
            formData.append('file', selectedFile);
        }

        axios
            .post(apiUrl, formData, {headers:header})
            .then((response) => {
                addNumber(response.data.daido_num);
                resetSelection();
                setIsLoading(false);
                // リクエストが完了した後の時刻
                const endTime = new Date().getTime();

                // 処理時間を計算
                const elapsedTime = endTime - startTime;
                setWholeTime(elapsedTime);
                setApiTime(Math.floor(response.data.processing_time));

                console.log(`APIの処理時間は${Math.floor(response.data.processing_time)}ミリ秒です。`);
                console.log(`全体の処理時間は${elapsedTime}ミリ秒です。`);
            })
            .catch(function(error){
                if(error.response.status){
                try {
                    setDetail(error.response.data.detail);
                } catch {}
                if(error.response.status == 403){
                    setAuthError(true);
                } else {
                    setApiError(true);
                    setIsLoading(false);
                }
                } else {
                try {
                    setDetail(error.message);
                } catch {}
                setApiError(true);
                setIsLoading(false);
                }
            })
        }

    function resetAll(){
        setNumbers([]);
    }

    useEffect(() => {
        if (selectedFile == null){
            return
        } else {
            runOcr();
        }
    }, [selectedFile]);

    useEffect(() => {
        if(!didMountRef.current){
            didMountRef.current = true;
        } else {
            resetAll();
        }
    }, [reset]);

    useEffect(()=>{
        setReqNumbers(numbers);
    },[numbers])

    return (
        <>
        <input
            type = "file"
            ref = {inputFileRef}
            accept = "image/*"
            capture = "environment"
            onChange = {handleFiles}
            style = {{...styles.inputFile}}
        />
        <img ref={imageContainerRef} alt ="" />
        <React.Fragment>
        <Card sx = {{paddingInline:1}}>
            <Grid container rowSpacing={0} direction = "column" display="flex"
            // alignItems="center"
            >
            <Grid item xs = {12}>
                <Typography
                    component = "h2"
                    variant = "h6"
                    textAlign = "left"
                    sx = {{m:1, fontWeight : 'bold'}}
                    >
                    大同現品票
                </Typography>
            </Grid>
            {numbers.map((number) => (
            <>
            {/* <Grid item xs = {12}> */}
            <TextField
                id = "製番"
                placeholder = "製番"
                variant = "outlined"
                margin = "dense"
                fullWidth
                // sx = {{ paddingInline:1}}
                InputProps={{
                    endAdornment: (number.deleteFlg?(<IconButton onClick = {() => handleDelete(number.id)}><CloseIcon /></IconButton>):(<></>))
                }}
                value={number.num}
                onChange={(e) => handleEdit(number.id, e.target.value)}
            />
            {/* </Grid> */}
            {number.deleteFlg && number.num !== '' &&
            <Grid item>
                <Typography textAlign="left" variant = "subtitle1" sx = {{color: 'error.main'}}>　※重複しています</Typography>
            </Grid>
            }
            </>
            ))}
            <Grid container display = 'flex' alignItems='center'>
                <Grid item xs={7}>
                    <Typography
                        component = "h2"
                        variant = "subtitle1"
                        sx = {{m:1, fontWeight : 'bold'}}
                        >
                        合計 {count} ロット
                    </Typography>
                </Grid>
                <Grid item xs={5} sx = {{paddingInline:1}}>
                    {numbers.length > 0 &&
                    <Button
                        variant = "contained"
                        color = "secondary"
                        onClick = {() => {setOpenReset(true);}}
                        fullWidth
                        startIcon = {<DeleteIcon />}
                        >
                            全削除
                        </Button>
                    }
                </Grid>
            </Grid>
            <Grid item xs = {12}>
                <LoadingButton
                        loading= {isLoading}
                        startIcon = {<CameraAltIcon />}
                        onClick = {openDialog}
                        fullWidth
                        variant = "contained"
                        size = "large"
                        sx= {{my:1}}
                        >追加</LoadingButton>
            </Grid>
            </Grid>
        </Card>
        API： {apiTime} ms
        Total： {wholeTime} ms
        </React.Fragment>
        <Dialog
            open = {openReset}
            onClose = {() => {}}
            fullWidth
        >
            <DialogTitle>確認</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    入力を全削除します。よろしいですか？
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick = {() => {setOpenReset(false)}}>キャンセル</Button>
                <Button onClick = {() => {
                    resetAll();
                    setOpenReset(false);
                }}>実行する</Button>
            </DialogActions>
        </Dialog>
        <CustomDialog
            title = "認証エラー"
            text = {detail+"：ログインし直してください"}
            buttonText = "確認"
            onClick = {()=>{
                localStorage.removeItem("authToken");
                localStorage.removeItem("userName");
                localStorage.removeItem("employeeId");
                setMenu(0);
            }}
            open = {authError}
        />
        <CustomDialog
            title = "通信エラー"
            text = {detail}
            buttonText = "確認"
            onClick = {()=>{
                setApiError(false);
            }}
            open = {apiError}
            />
        </>
    );
}
export default CardForInspection;