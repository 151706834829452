import { ChangeEventHandler, MouseEventHandler, useRef, useState } from "react";
import { imageDisplaySize } from '../styles';

const fileImage = new Image();

export const useHooks = () => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile ] = useState<File | null>(null);
  const imageContainerRef = useRef<HTMLImageElement>(null);
  const [objectURL, setObjectURL] = useState("");

  // BlobからFileオブジェクトを生成する
  function blobToFile(blob: Blob, fileName: string): File {
    // Blobと同じプロパティを持つFileオブジェクトを生成
    return new File([blob], fileName, { type: blob.type, lastModified: new Date().getTime() });
  }

  // resizeImage関数
  function resizeImage(file: File, callback: (resizedFile: File) => void): void {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = function() {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        throw new Error("Contextが取得できませんでした。");
      }

      // 縦横比を保持したままリサイズ
      const maxSize = 640;
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width;
          width = maxSize;
        }
      } else {
        if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }
      }

      canvas.width = width;
      canvas.height = height;

      // キャンバスに画像を描画
      ctx.drawImage(img, 0, 0, width, height);

      // canvasをjpegとして出力
      canvas.toBlob((blob: Blob | null) => {
        if (!blob) {
          throw new Error("Blobが生成できませんでした。");
        }
        // BlobからFileオブジェクトに変換
        const resizedFile = blobToFile(blob, "resized_image.jpg");
        callback(resizedFile);
      }, "image/jpeg", 0.8);
    };
  }

  const manipulateImageSize = (url:string) => {
    fileImage.src = url;
    fileImage.onload = () => {
			const width = fileImage.naturalWidth;
			const height = fileImage.naturalHeight;
			const ratioWidth = width / imageDisplaySize.width;
			const ratioHeight = height / imageDisplaySize.height;
			if (ratioWidth > ratioHeight) {
				fileImage.width = imageDisplaySize.width;
				fileImage.height = height / ratioWidth;
			} else {
				fileImage.width = width / ratioHeight;
				fileImage.height = imageDisplaySize.height;
			}
		};
  }
  const resetSelection = () => {
    fileImage.src = "";
    setSelectedFile(null);
    const imageContainer = imageContainerRef.current;
    if (imageContainer && fileImage.parentNode === imageContainer) {
      imageContainer.removeChild(fileImage);
    }
    if (objectURL) {
      window.URL.revokeObjectURL(objectURL);
      setObjectURL("");
    }
  };

  const handleFiles: ChangeEventHandler<HTMLInputElement> = (event) => {
    const files = event.currentTarget.files;
    resetSelection();
    if (!files || files?.length === 0) return;
    let file = files[0];
    if (!file.type.includes("image/")) {
      event.currentTarget.value = "";
      return;
    }

    resizeImage(file, function(resizedFile: File){
      setSelectedFile(resizedFile);
      }
    )

    const imageContainer = imageContainerRef.current;
    if (!imageContainer) return;
    const objectURL = window.URL.createObjectURL(file);

    manipulateImageSize(objectURL);
    imageContainer.appendChild(fileImage);
    setObjectURL(objectURL);
  };

  const openDialog: MouseEventHandler<HTMLButtonElement>= () => {
    resetSelection();
    const inputFile = inputFileRef.current;
    if (!inputFile) return;
    inputFile.click();
  }

  return { handleFiles, imageContainerRef, inputFileRef, openDialog, resetSelection, objectURL, selectedFile };
};
