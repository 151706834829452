import { Button, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import { NumberObj } from "../types/NumberObj";
import { BottomButton } from "../molecules/BottomButton";
import CustomDialog from "../molecules/CustomDialog";
import { Title } from "../molecules/Title";
import { UserName } from "../molecules/UserName";
import CardForInspection from "../organisms/CardForInspection";
// import ShowResult from "./ShowResult";
import axios from 'axios';
import ShowResult from "../organisms/ShowResult";

function Inspection({menu, userName, setMenu}:{menu:number, userName:string, setMenu:React.Dispatch<React.SetStateAction<number>>}) {
  const [ openSuccess, setOpenSuccess ] = useState(false);
  const [ openFailure, setOpenFailure ] = useState(false);

  const [ reset, setReset ] = useState(false);
  const [ reqNumbers, setReqNumbers ] = useState<NumberObj[]>([]);

  const [ resp, setResp ] = useState<any>([]);

  const [ detail, setDetail ] = useState("");
  const [ authError, setAuthError ] = useState(false);
  const [ apiError, setApiError ] = useState(false);

  const executeInspection = async() => {
    const apiUrl = process.env.REACT_APP_ROOT_URL + 'inspection/';
    const employee_id = localStorage.getItem("employeeId");

    const requestBody = {'numbers':reqNumbers, 'employee_id':employee_id}
    const header = {'content-type':'application/json','Authorization':'Bearer '+ localStorage.getItem("authToken")}

    axios
      .post(apiUrl, requestBody, {headers:header})
      .then((response) => {
        if(response.data.isAllOK){
          setResp([]);
          setOpenSuccess(true);
        } else {
          setResp(response.data.results);
          setOpenFailure(true);
        }
      })
      .catch(function(error){
        if(error.response.status){
          try {
            setDetail(error.response.data.detail);
          } catch {}
          if(error.response.status == 403){
            setAuthError(true);
          } else {
            setApiError(true);
          }
        } else {
          try {
            setDetail(error.message);
          } catch {}
          setApiError(true);
        }
      })
  }

  return (
      <>
        <Title>素材検品</Title>
        <UserName>{userName}</UserName>
        <Container component = "main" maxWidth = "xs">
        <Grid container
          rowSpacing = {0}
          display = "flex"
          // direction = "column"
          justifyContent = "center"
          >
          {resp.length !== 0 &&
          <Grid item xs = {12}>
            <ShowResult results={resp} setMenu={setMenu} setResp={setResp} />
          </Grid>
          }
          <Grid item xs = {12}>
            <CardForInspection reset = {reset} setReqNumbers ={setReqNumbers} setMenu={setMenu} />
          </Grid>
          <Grid item xs = {6}>
            <Button
              // startIcon = {<LoginIcon />}
              variant = "contained"
              fullWidth
              size = "large"
              sx = {{my:2}}
              onClick = {() => {executeInspection()}}
              disabled = {!(reqNumbers.length>0)}
              >
                照合
            </Button>
          </Grid>
        </Grid>
      </Container>
      <CustomDialog
          title = "照合OK"
          buttonText = "確認"
          // flagOnClose
          onClick = {()=>{
            setReset(!reset);
            setOpenSuccess(false);
          }}
          open = {openSuccess}
          bgcolor = '#4caf50' //success light
        />
        <CustomDialog
          title = "実行完了"
          text = '登録できなかったデータがあります。確認してください'
          buttonText = "確認"
          // flagOnClose
          onClick = {()=>{
            setReset(!reset);
            setOpenFailure(false);
          }}
          open = {openFailure}
          bgcolor = '#ef5350' //error light
        />
        <CustomDialog
            title = "認証エラー"
            text = {detail+"：ログインし直してください"}
            buttonText = "確認"
            onClick = {()=>{
              localStorage.removeItem("authToken");
              localStorage.removeItem("userName");
              localStorage.removeItem("employeeId");
              setMenu(0);
            }}
            open = {authError}
          />
        <CustomDialog
            title = "通信エラー"
            text = {detail}
            buttonText = "確認"
            onClick = {()=>{
              setApiError(false);
            }}
            open = {apiError}
          />
        <BottomButton onClick = {() => setMenu(0)}>TOP MENU</BottomButton>
      </>
    );
  }
  export default Inspection;