import { Typography } from "@mui/material";
import { useEffect, useState } from "react";

export const LastTest = ({needTest, setNeedTest}:{needTest:boolean, setNeedTest:React.Dispatch<React.SetStateAction<boolean>>}) => {
    const testedAt = localStorage.getItem("testedAt");
    const [ testedAtStr, setTestedAtStr ] = useState("");
    // const [ needTest, setNeedTest ] = useState(false);
    const calculateLatestWorkStartingTime = (date:Date) =>{
        if (date.getHours() < 8) {
            date.setDate(date.getDate() - 1);
        }
          // 現在の日付の午前8時を計算する
        const latestWorkStartingTime = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            8, // 時間を8時に設定する
            0, // 分を0分に設定する
            0, // 秒を0秒に設定する
            0  // ミリ秒を0ミリ秒に設定する
        );

        return latestWorkStartingTime
    }

    useEffect(() => {
        if(testedAt){
            const date: Date = new Date(testedAt);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hour = String(date.getHours()).padStart(2, '0');
            const minute = String(date.getMinutes()).padStart(2, '0');
            setTestedAtStr (`${year}/${month}/${day} ${hour}:${minute}`);
            const now = new Date();
            const lastTime = calculateLatestWorkStartingTime(now);
            setNeedTest(lastTime>date);
        } else {
            setNeedTest(true);
        }
    },[testedAt, setNeedTest]);

    return(
        <>
        {testedAt &&
        <Typography textAlign = "right" component = "h2" variant = "subtitle1" margin = {2} >最終点検日：{testedAtStr}</Typography>
        }
        {needTest &&
        <Typography textAlign = "center" component = "h2" variant = "h6" margin = {2} color="error">※点検を実施してください</Typography>
        }
        </>
    )
}