import React, { useEffect, useRef, useState } from "react";
import { BottomButton } from "../molecules/BottomButton";
import { Button, Card, Grid, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { Title } from "../molecules/Title";
import { UserName } from "../molecules/UserName";
// import CustomDialog from "../molecules/CustomDialog";
import CustomDialog from "../molecules/CustomDialog";
import OcrCard from "../organisms/CardForOcr";
import axios from 'axios';
import { NumberInfo, defaultNumber } from "../types/NumberInfo";

function Collation({menu, userName, setMenu}:{menu:number, userName:string, setMenu:React.Dispatch<React.SetStateAction<number>>}) {
  const labels = [["大同現品票","東北命令書"],["大同現品票","東北現品票"],["東北命令書","東北現品票"]]
  const docTypes = [[1,2],[1,3],[2,3]]
  const [ openSuccess, setOpenSuccess ] = useState(false);
  const [ openFailure, setOpenFailure ] = useState(false);
  const [ num1, setNum1 ] = useState<NumberInfo>(defaultNumber);
  const [ readyToSend1, setReadyToSend1 ] = useState(false);
  const [ num2, setNum2 ] = useState<NumberInfo>(defaultNumber);
  const [ readyToSend2, setReadyToSend2 ] = useState(false);
  const [ reset, setReset ] = useState(false);
  const [ detail, setDetail ] = useState("");
  const [ authError, setAuthError ] = useState(false);
  const [ apiError, setApiError ] = useState(false);

  const runCollation = async() => {
    const apiUrl = process.env.REACT_APP_ROOT_URL + 'collation/';
    const employee_id = localStorage.getItem("employeeId");
    const requestBody = {'collation_type':menu,'num_1':num1, 'num_2':num2, 'employee_id':employee_id}
    const header = {'content-type':'application/json','Authorization':'Bearer '+ localStorage.getItem("authToken")}

    axios
      .post(apiUrl, requestBody, {headers:header})
      .then((response) => {
        console.log(response.data);
        if(response.data.result == 1){
          setOpenSuccess(true);
        } else {
          setOpenFailure(true);
        }
      })
      .catch(function(error){
        if(error.response.status){
          try {
            setDetail(error.response.data.detail);
          } catch {}
          if(error.response.status == 403){
            setAuthError(true);
          } else {
            setApiError(true);
          }
        } else {
          try {
            setDetail(error.message);
          } catch {}
          setApiError(true);
        }
      })
  }

  return (
      <>
        <Title>照合({labels[menu-1][0]}⇔{labels[menu-1][1]})</Title>
        <UserName>{userName}</UserName>
        <Container component = "main" maxWidth = "xs">
          <Grid container
            rowSpacing = {1}
            display = "flex"
            direction = "column"
            >
            <Grid item>
              <OcrCard numberInfo = {num1} setNumberInfo = {setNum1} setReadyToSend={setReadyToSend1}  reset = {reset} menu={menu} setMenu={setMenu} expectedDoc={docTypes[menu-1][0]}>{labels[menu-1][0]}</OcrCard>
            </Grid>
            <Grid item>
              <OcrCard numberInfo = {num2} setNumberInfo = {setNum2}setReadyToSend={setReadyToSend2}  reset = {reset} menu={menu} setMenu={setMenu} expectedDoc={docTypes[menu-1][1]}>{labels[menu-1][1]}</OcrCard>
            </Grid>
            <Grid item>
              <Button
                variant = "contained"
                fullWidth
                size = "large"
                onClick = {() => runCollation()}
                disabled = {!(readyToSend1&&readyToSend2)}
                >
                  照合
              </Button>
            </Grid>
          </Grid>
        </Container>
        <CustomDialog
          title = "照合OK"
          buttonText = "確認"
          // flagOnClose
          onClick = {()=>{
            setReset(!reset);
            setOpenSuccess(false);
          }}
          open = {openSuccess}
          bgcolor = '#4caf50' //success light
        />
        <CustomDialog
          title = "照合NG"
          text = '『不一致』  確認してください'
          buttonText = "解除"
          // flagOnClose
          onClick = {()=>{
            setOpenFailure(false);
          }}
          open = {openFailure}
          bgcolor = '#ef5350' //error light
        />
        <CustomDialog
            title = "認証エラー"
            text = {detail+"：ログインし直してください"}
            buttonText = "確認"
            onClick = {()=>{
              localStorage.removeItem("authToken");
              localStorage.removeItem("userName");
              localStorage.removeItem("employeeId");
              setMenu(0);
            }}
            open = {authError}
            // bgcolor = '#ef5350' //error light
          />
        <CustomDialog
            title = "通信エラー"
            text = {detail}
            buttonText = "確認"
            onClick = {()=>{
              setApiError(false);
            }}
            open = {apiError}
            // bgcolor = '#ef5350' //error light
          />
        <BottomButton icon = "" onClick = {() => setMenu(0)}>TOP MENU</BottomButton>
      </>
    );
  }
  export default Collation;