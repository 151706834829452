import { Button, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import { BottomButton } from "../molecules/BottomButton";
import CustomDialog from "../molecules/CustomDialog";
import { Title } from "../molecules/Title";
import { UserName } from "../molecules/UserName";
import OcrCard from "../organisms/CardForOcr";
import axios from 'axios';
import { NumberInfo, defaultNumber } from "../types/NumberInfo";

function StorageSearch({menu, userName, setMenu}:{menu:number, userName:string, setMenu:React.Dispatch<React.SetStateAction<number>>}) {
  const [openSuccess, setOpenSuccess ] = useState(false);
  const [openFailure, setOpenFailure ] = useState(false);

  const [ num1, setNum1 ] = useState<NumberInfo>(defaultNumber);
  const [result, setResult] = useState("");

  const [reset, setReset] = useState(false);

  const runSearch = async() => {
    const apiUrl = process.env.REACT_APP_ROOT_URL + 'storage-search/'
    const requestBody = {'num':num1.num}
    const header = {'content-type':'application/json'}

    axios
      .post(apiUrl, requestBody, {headers:header})
      .then((response) => {
        console.log(response.data);
        if (response.data.storage[0]){
          setResult("検索結果『"+response.data.storage[0]+"』")
          setOpenSuccess(true)
        } else {
          setOpenFailure(true)
        }
      })
      .catch(() => {
        setOpenFailure(true)
      })
  }

  return (
    <>
      <Title>置場確認</Title>
      <UserName>{userName}</UserName>
      <Container component = "main" maxWidth = "xs">
        <Grid container
          rowSpacing = {1}
          display = "flex"
          direction = "column"
          >
          <Grid item>
              <OcrCard numberInfo = {num1} setNumberInfo = {setNum1} reset = {reset} menu={menu} setMenu={setMenu}>現品票/命令書</OcrCard>
            </Grid>
          <Grid item>
            <Button
              variant = "contained"
              fullWidth
              size = "large"
              onClick = {() => runSearch()}
              disabled = {num1.num == ""}
              >
                検索
            </Button>
          </Grid>
        </Grid>
      </Container>
      <CustomDialog
          title = "検索完了"
          text= {result}
          buttonText = "確認"
          // flagOnClose
          onClick = {()=>{
            setReset(!reset);
            setOpenSuccess(false);
          }}
          open = {openSuccess}
          bgcolor = '#4caf50' //success light
        />
        <CustomDialog
          title = "検索失敗"
          text = '製番を確認してください'
          buttonText = "確認"
          // flagOnClose
          onClick = {()=>{
            setOpenFailure(false);
          }}
          open = {openFailure}
          bgcolor = '#ef5350' //error light
        />
      <BottomButton onClick = {() => setMenu(0)}>TOP MENU</BottomButton>
    </>
    );
  }
  export default StorageSearch;