import { Button, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import { BottomButton } from "../molecules/BottomButton";
import CustomDialog from "../molecules/CustomDialog";
import { Title } from "../molecules/Title";
import { UserName } from "../molecules/UserName";
import OcrCard from "../organisms/CardForOcr";
import axios from 'axios';
import { defaultNumber, NumberInfo } from "../types/NumberInfo";

function StorageRegister({menu, userName, setMenu}:{menu:number, userName:string, setMenu:React.Dispatch<React.SetStateAction<number>>}) {
  const [ openSuccess, setOpenSuccess ] = useState(false);
  const [ openFailure, setOpenFailure ] = useState(false);
  // const [ num1, setNum1 ] = useState("");
  // const [ num1_ai, setNum1_ai ] = useState("");
  // const [ docType, setDocType ] = useState(1);
  const [ num1, setNum1 ] = useState<NumberInfo>(defaultNumber);
  const [ num2, setNum2 ] = useState<NumberInfo>(defaultNumber);
  // const [ num2, setNum2 ] = useState("");
  // const [ num2_ai, setNum2_ai ] = useState("");
  const [ reset, setReset ] = useState(false);
  const [ detail, setDetail ] = useState("");
  const [ authError, setAuthError ] = useState(false);
  const [ apiError, setApiError ] = useState(false);

  const runRegister = async() => {
    const apiUrl = process.env.REACT_APP_ROOT_URL + 'storage-register/';
    const employee_id = localStorage.getItem("employeeId");
    const requestBody = {'num':num1, 'storage':num2.num, 'employee_id':employee_id}
    const header = {'content-type':'application/json','Authorization':'Bearer '+ localStorage.getItem("authToken")}

    axios
      .post(apiUrl, requestBody, {headers:header})
      .then((response) => {
        setOpenSuccess(true);
      })
      .catch(function(error){
        if(error.response.status){
          try {
            setDetail(error.response.data.detail);
          } catch {}
          if(error.response.status == 403){
            setAuthError(true);
          } else {
            setApiError(true);
          }
        } else {
          try {
            setDetail(error.message);
          } catch {}
          setApiError(true);
        }
      })
  }

  return (
    <>
      <Title>置場管理</Title>
      <UserName>{userName}</UserName>
      <Container component = "main" maxWidth = "xs">
          <Grid container
            rowSpacing = {1}
            display = "flex"
            direction = "column"
            >
            <Grid item>
              <OcrCard numberInfo = {num1} setNumberInfo = {setNum1} reset = {reset} menu={menu} setMenu={setMenu}>現品票/命令書</OcrCard>
            </Grid>
            <Grid item>
              <OcrCard numberInfo = {num2} labelText = "置場名" setNumberInfo = {setNum2} reset = {reset} menu={menu} setMenu={setMenu} ocrType = {0}>材料枠</OcrCard>
            </Grid>
            <Grid item>
              <Button
                // startIcon = {<LoginIcon />}
                variant = "contained"
                fullWidth
                size = "large"
                // sx = {{mt:1, mb:1}}
                onClick = {() => runRegister()}
                disabled = {!(num1.num !=="" && num2.num !== "")}
                >
                  登録
              </Button>
            </Grid>
          </Grid>
        </Container>
        <CustomDialog
          title = "登録完了"
          text = {'『' + num2.num + '』に登録'}
          buttonText = "確認"
          // flagOnClose
          onClick = {()=>{
            setReset(!reset);
            setOpenSuccess(false);
          }}
          open = {openSuccess}
          bgcolor = '#4caf50' //success light
        />
        <CustomDialog
          title = "登録失敗"
          text = '入力値を確認してください'
          buttonText = "解除"
          // flagOnClose
          onClick = {()=>{
            setOpenFailure(false);
          }}
          open = {openFailure}
          bgcolor = '#ef5350' //error light
        />
        <CustomDialog
            title = "認証エラー"
            text = {detail+"：ログインし直してください"}
            buttonText = "確認"
            onClick = {()=>{
              localStorage.removeItem("authToken");
              localStorage.removeItem("userName");
              localStorage.removeItem("employeeId");
              setMenu(0);
            }}
            open = {authError}
          />
        <CustomDialog
            title = "通信エラー"
            text = {detail}
            buttonText = "確認"
            onClick = {()=>{
              setApiError(false);
            }}
            open = {apiError}
          />
      <BottomButton onClick = {() => setMenu(0)}>TOP MENU</BottomButton>
    </>
    );
  }
  export default StorageRegister;