import './App.css';
import TopMenu from './pages/TopMenu'


function App() {

  return (
    <div className = "App">
      <TopMenu />
    </div>
  );
}

export default App;
