export type NumberInfo = {
    num: string,
    num_ai: string,
    doc_type:number | undefined,
    doc_type_ai:number | undefined,
    file_path: string,
    ocr_processing_time:number|undefined,
    api_processing_time:number|undefined,
    total_processing_time:number|undefined,
};

export const defaultNumber:NumberInfo = {
    'num':'',
    'num_ai':'',
    'doc_type':undefined,
    'doc_type_ai':undefined,
    'file_path':'',
    'ocr_processing_time':undefined,
    'api_processing_time':undefined,
    'total_processing_time':undefined
};