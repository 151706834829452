import { Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, List, ListItem, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import axios from 'axios';
import CustomDialog from "../molecules/CustomDialog";

function ShowResult({results, setMenu, setResp}:{results:any[], setMenu:React.Dispatch<React.SetStateAction<number>>, setResp:React.Dispatch<any>}) {
  const irregulars = results?.filter(res => res.result > 2)
  const [hasIrregular, setHasIrregular] = useState(false);

  const [ detail, setDetail ] = useState("");
  const [ authError, setAuthError ] = useState(false);
  const [ apiError, setApiError ] = useState(false);
  const [ openSuccess, setOpenSuccess ] = useState(false);
  const [ openConfirmation, setOpenConfirmation ] = useState(false);

  useEffect(()=> {
    if (irregulars && irregulars.length>0){
      setHasIrregular(true);
    } else {
      setHasIrregular(false);
    }
  },[irregulars]);

  const executeForceRegister = async() => {
    const apiUrl = process.env.REACT_APP_ROOT_URL + 'force-register/';
    const employee_id = localStorage.getItem("employeeId");

    const requestBody = {'numbers':irregulars, 'employee_id':employee_id}
    const header = {'content-type':'application/json','Authorization':'Bearer '+ localStorage.getItem("authToken")}

    axios
      .post(apiUrl, requestBody, {headers:header})
      .then(() => {
        setOpenSuccess(true);
      })
      .catch(function(error){
        if(error.response.status){
          try {
            setDetail(error.response.data.detail);
          } catch {}
          if(error.response.status == 403){
            setAuthError(true);
          } else {
            setApiError(true);
          }
        } else {
          try {
            setDetail(error.message);
          } catch {}
          setApiError(true);
        }
      })
  }

  return (
    <>
    <Card sx = {{width:"100%"}}>
      <Grid container
          // rowSpacing = {0.5}
          display = "flex"
          text-align = "center"
          justifyContent= "center"
          >
      <Grid item xs ={12}>
        <Typography
          component = "h2" variant = "subtitle1" color = 'error.main' textAlign='left'
          sx = {{m:1}}>照合できない素材があります。確認してください</Typography>
      </Grid>
      <Grid item xs = {12}>
        <List dense = {true}>
        {results?results.map(result =>(
          <ListItem>
            <Grid item xs = {12} container display='flex' alignItems = 'center'>
              <Grid item xs = {5}>
                <Typography textAlign="center">{result.num.num}</Typography>
              </Grid>
              <Grid item xs = {7} textAlign="left">{result.result === 1 ? <Typography color = 'success.main' sx ={{fontWeight: 'bold'}}>完了</Typography>:
            result.result === 4 ? <Typography color = 'error.main' sx ={{fontWeight: 'bold'}}>明細データなし</Typography>:
            result.result === 2 ? <Typography sx = {{fontWeight:'bold'}}>登録済み</Typography>:<Typography color = 'error.main' sx = {{fontWeight:'bold'}}>登録済み（{result.date_time}）</Typography>}</Grid>
            </Grid>
          </ListItem>
          )):<></>}
        </List>
      </Grid>
      <Grid item xs = {6}>
          <Button
            disabled = {!hasIrregular}
            variant = "contained"
            fullWidth
            size = "large"
            color = "secondary"
            sx = {{m:1}}
            onClick = {() => {setOpenConfirmation(true)}}>
              強制登録
          </Button>
      </Grid>
      </Grid>
    </Card>
    <CustomDialog
          title = "登録完了"
          text = "異常報告を完了しました"
          buttonText = "確認"
          // flagOnClose
          onClick = {()=>{
            setResp([]);
            setOpenSuccess(false);
          }}
          open = {openSuccess}
        />
    <CustomDialog
      title = "認証エラー"
      text = {detail+"：ログインし直してください"}
      buttonText = "確認"
      onClick = {()=>{
        localStorage.removeItem("authToken");
        localStorage.removeItem("userName");
        localStorage.removeItem("employeeId");
        setMenu(0);
      }}
      open = {authError}
    />
  <CustomDialog
      title = "通信エラー"
      text = {detail}
      buttonText = "確認"
      onClick = {()=>{
        setApiError(false);
      }}
      open = {apiError}
    />
    <Dialog
            open = {openConfirmation}
            onClose = {() => {}}
            fullWidth
        >
            <DialogTitle>確認</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    異常報告をしますか？
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick = {() => {setOpenConfirmation(false)}}>キャンセル</Button>
                <Button onClick = {() => {
                    executeForceRegister();
                    setOpenConfirmation(false);
                }}>実行する</Button>
            </DialogActions>
        </Dialog>
    </>
    );
  }

  export default ShowResult;
