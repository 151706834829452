import { ReactNode, useEffect, useRef, useState } from "react";
import { CameraCard } from "../molecules/CameraCard";
import { useHooks } from "../hooks/imageHooks";
import { styles } from "../styles";
import axios from 'axios';
import CustomDialog from "../molecules/CustomDialog";
import React from "react";
import { FormControlLabel, Switch, Typography } from "@mui/material";
import { defaultNumber, NumberInfo } from "../types/NumberInfo";
import { NewCameraCard } from "../molecules/NewCameraCard";

type CardForOcrProps ={
    labelText?: string,
    buttonText?: string,
    numberInfo: NumberInfo,
    setNumberInfo: React.Dispatch<React.SetStateAction<NumberInfo>>,
    // number: string,
    // setNumber:React.Dispatch<React.SetStateAction<string>>,
    // setNumberPredicted:React.Dispatch<React.SetStateAction<string>>,
    reset: boolean,
    children: ReactNode,
    flgReadOnly?: boolean,
    menu: number,
    setMenu:React.Dispatch<React.SetStateAction<number>>,
    // setDocumentType?:React.Dispatch<React.SetStateAction<number>>,
    ocrType?: number,
    expectedDoc?:number,
    setReadyToSend?: React.Dispatch<React.SetStateAction<boolean>>,
}

function CardForCollation(props:CardForOcrProps) {
    const { labelText = "製番", buttonText = "読取り", numberInfo, setNumberInfo, reset, children, flgReadOnly = false, menu, setMenu, ocrType=1, expectedDoc=undefined, setReadyToSend} = props
    const { handleFiles, imageContainerRef, inputFileRef, openDialog, resetSelection, objectURL, selectedFile } = useHooks();
    // const textRef = useRef<any>();
    const didMountRef = useRef(false);

    const [ detail, setDetail ] = useState("");
    const [ authError, setAuthError ] = useState(false);
    const [ apiError, setApiError ] = useState(false);

    const [ isWrong, setIsWrong ] = useState(false);
    const [ ignoreWarning, setIgnoreWarning ] = useState(false);

    const [ isLoading, setIsLoading ] = useState(false);
    // const [ apiTime, setApiTime ] = useState(0);
    // const [ wholeTime, setWholeTime ] = useState(0);
    // const [ readyToSend, setReadyToSend ] = useState(false);

    useEffect(() => {
        console.log(numberInfo);
    }, [numberInfo]);

    useEffect(() => {
        // console.log(readyToSend);
        if(setReadyToSend){
            (numberInfo.num !== "" && (!isWrong || ignoreWarning))?setReadyToSend(true):setReadyToSend(false)
        }
    }, [isWrong, ignoreWarning, numberInfo.num]);

    useEffect(() => {
        if (selectedFile == null){
            setNumberInfo(defaultNumber)
            if(expectedDoc){
                setNumberInfo({...numberInfo, 'doc_type':expectedDoc});
            }
            // textRef.current.value = "";
            setIsWrong(false);
            setIgnoreWarning(false);
        } else if (ocrType == 0){
            runStorageOcr();
        } else {
            runDocumentOcr();
        }
    }, [selectedFile]);

    useEffect(() => {
        if(!didMountRef.current){
            didMountRef.current = true;
        } else {
            // setNumberInfo(defaultNumber)
            let doc;
            if(expectedDoc){
                doc = expectedDoc
            } else {
                doc = undefined
            }
            setNumberInfo({...defaultNumber, 'doc_type':doc});
            resetSelection();
            // textRef.current.value = "";
            setIsWrong(false);
            setIgnoreWarning(false);
        }
    }, [reset]);

    const runStorageOcr = async() => {
        const apiUrl = process.env.REACT_APP_ROOT_URL + 'storage-ocr/';
        const header = {'content-type':'application/json','Authorization':'Bearer '+ localStorage.getItem("authToken")}

        setIsLoading(true);
        const startTime = new Date().getTime();

        const formData = new FormData();
        if (selectedFile !== null) {
            formData.append('file', selectedFile);
        }

        axios
            .post(apiUrl, formData, {headers:header})
            .then((response) => {
                setNumberInfo({...numberInfo, 'num':response.data.storage, 'num_ai':response.data.storage});
                setIsLoading(false);
            })
            .catch(function(error){
                if(error.response.status){
                try {
                    setDetail(error.response.data.detail);
                } catch {}
                if(error.response.status == 403){
                    setAuthError(true);
                    setIsLoading(false);
                } else {
                    setApiError(true);
                    setIsLoading(false);
                }
                } else {
                try {
                    setDetail(error.message);
                } catch {}
                setApiError(true);
                setIsLoading(false);
                }
            })
        // リクエストが完了した後の時刻
        const endTime = new Date().getTime();

        // 処理時間を計算
        const elapsedTime = endTime - startTime;

        console.log(`APIの処理時間は${elapsedTime}ミリ秒です。`);
        }

    const runDocumentOcr = async() => {
        const apiUrl = process.env.REACT_APP_ROOT_URL + 'document-ocr/';
        const requestBody = {'document_type':expectedDoc}
        const header = {'content-type':'application/json','Authorization':'Bearer '+ localStorage.getItem("authToken")}

        setIsLoading(true);
        const startTime = new Date().getTime();

        const formData = new FormData();
        if(expectedDoc !== undefined){
            formData.append('document_type', String(expectedDoc));
        }
        if (selectedFile !== null) {
            formData.append('file', selectedFile);
        }


        axios
            .post(apiUrl, formData, {headers:header})
            .then((response) => {
                let num;
                if(menu == 3 || (menu==5 && response.data.doc_type != 1) || (menu==6 && response.data.doc_type == 2)){
                    // setNumberInfo({...numberInfo, 'num':response.data.tohoku_num, 'num_ai':response.data.tohoku_num,'doc_type':response.data.doc_type});
                    num = response.data.tohoku_num;
                } else {
                    // setNumberInfo({...numberInfo, 'num':response.data.daido_num, 'num_ai':response.data.daido_num,'doc_type':response.data.doc_type});
                    num = response.data.daido_num;
                }
                if(expectedDoc){
                    if(expectedDoc !== response.data.doc_type){
                        setIsWrong(true);
                    }
                }
                setIsLoading(false);
                // リクエストが完了した後の時刻
                const endTime = new Date().getTime();

                // 処理時間を計算
                const elapsedTime = endTime - startTime;
                // setWholeTime(elapsedTime);
                // setApiTime(Math.floor(response.data.processing_time));
                setNumberInfo({...numberInfo,
                    'num':num, 'num_ai':num, 'doc_type_ai':response.data.doc_type,
                    'file_path':response.data.file_name,
                    'ocr_processing_time':Math.floor(response.data.ocr_time),
                    'api_processing_time':Math.floor(response.data.processing_time),
                    'total_processing_time':elapsedTime});
            })
            .catch(function(error){
                if(error.response.status){
                try {
                    setDetail(error.response.data.detail);
                } catch {}
                if(error.response.status == 403){
                    setAuthError(true);
                    setIsLoading(false);
                } else {
                    setApiError(true);
                    setIsLoading(false);
                }
                } else {
                try {
                    setDetail(error.message);
                } catch {}
                setApiError(true);
                setIsLoading(false);
                }
            })
        }

    return (
        <>
        <input
            type = "file"
            ref = {inputFileRef}
            accept = "image/*"
            capture = "environment"
            onChange = {handleFiles}
            style = {{...styles.inputFile}}
        />
        <img ref={imageContainerRef} alt ="" />
        <NewCameraCard
            buttonText = {buttonText}
            onButtonClick = {openDialog}
            textLabel = {labelText}
            // onTextChange = {(e) => setNumber(e.target.value)}
            numberInfo = {numberInfo}
            setNumberInfo = {setNumberInfo}
            imageURL = {objectURL}
            // textRef = {textRef}
            flgReadOnly = {flgReadOnly}
            loading = {isLoading}
            >
            {children}
        </NewCameraCard>
        { isWrong &&
        <div style = {{display:'flex'}}>
            <Typography textAlign="left" variant = "subtitle1" sx = {{color: 'error.main', my:1}}>※読取り用紙が違います　</Typography>
            <FormControlLabel control={
            <Switch checked={ignoreWarning}
            onChange={()=> {setIgnoreWarning(!ignoreWarning)}}
            inputProps={{ 'aria-label': 'controlled' }} />
            } label="無視して送信" />
        </div>
        }
        OCR: {numberInfo.ocr_processing_time} ms
        API： {numberInfo.api_processing_time} ms
        Total： {numberInfo.total_processing_time} ms
        <CustomDialog
            title = "認証エラー"
            text = {detail+"：ログインし直してください"}
            buttonText = "確認"
            onClick = {()=>{
                localStorage.removeItem("authToken");
                localStorage.removeItem("userName");
                localStorage.removeItem("employeeId");
                setMenu(0);
            }}
            open = {authError}
        />
        <CustomDialog
            title = "通信エラー"
            text = {detail}
            buttonText = "確認"
            onClick = {()=>{
                setApiError(false);
            }}
            open = {apiError}
            />
        </>
    );
}
export default CardForCollation;